import fetchMethodRequest from "./service";

export const getAmenities = async () => {
    try {
        const response = await fetchMethodRequest('GET', 'settings/getAmenities');
        if (response && response.respCode) {
            return response.amenities;
        }
        return null;
    } catch (err) {
        return null;
    }
};